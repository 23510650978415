// import React from "react";
// import { navigate } from "gatsby";
// import Cookies from "universal-cookie";

// import Layout from "../../components/layout";
// import Seo from "../../components/seo";

// import Agegate from "../../components/agegate";

// const AgeGate = () => {
//   let ageCookie = new Cookies();

//   if (ageCookie.get("isOldEnough")) {
//     navigate("/en");
//   }

//   return (
//     <Layout>
//       <Seo title="Age Gate | Snickers Hunger Insurance" lang="en" />

//       <Agegate />
//     </Layout>
//   );
// };

// export default React.memo(AgeGate);

import React, { useEffect } from "react";
import { navigate } from "gatsby";
import Cookies from "universal-cookie";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import Agegate from "../../components/agegate";

const AgeGate = (props) => {
  console.log(props);
  // useEffect(() => {
  //   if (props.path.startsWith("/")) {
  //     document.querySelector("body").classList.add("rightToLeft");
  //   }
  // }, []);
  let ageCookie = new Cookies();

  // if (ageCookie.get("isOldEnough")) {
  //   navigate("/en/stores");
  // }

  return (
    <Layout>
      <Seo title="Age Gate | Snickers Hunger Insurance" lang="en" />
      <Agegate />
    </Layout>
  );
};

export default React.memo(AgeGate);
